//13 client slider


window.isPaused = false;
window.onTimerChangemob = function onTimerChangemob() {


    var isPaused = false;

    if (!window.isPaused) {



        var firstLI = document.getElementById("product_listclientsmob").firstElementChild;
        var lastLI = document.getElementById("product_listclientsmob").lastElementChild;
        // var lightgreen = false;
        // var red = false;
        // var purple = false;
        // var blue = false;
        // var green = false;

        var list = document.getElementById("product_listclientsmob"); // Get the <ul> element to insert a new node

        // if (list.classList.contains('LIGHTGREEN')) {
        //     lightgreen = true;
        // }
        // if (lightgreen) {
        //     list.children[1].classList.contains('client--intro').add('boxshadowlightgreen');
        // }

        lastLI.insertAdjacentElement("afterend", firstLI);
        // var currentId = list.children[2].id;
        // currentId.classList.remove("");

        var currentId = list.children[1].id;
        if (currentId === 'client1mob') {
            document.getElementById("clientname1mob").style.display = "block";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.add('dotactive');

        } else if (currentId === 'client2mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'block';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';


            document.getElementById("dotclientsmob1").classList.add('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === 'client3mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'block';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';



            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.add('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === 'client4mob') {
            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'block';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.add('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === 'client5mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'block';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.add('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');

        } else if (currentId === 'client6mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'block';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.add('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === 'client7mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'block';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.add('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');

        } else if (currentId === 'client8mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'block';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.add('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === 'client9mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'block';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.add('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');

        } else if (currentId === 'client10mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'block';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.add('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');

        } else if (currentId === 'client11mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'block';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.add('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === 'client12mob') {
            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'block';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.add('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');

        } else if (currentId === 'client13mob') {

            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'block';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.add('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');

        } else if (currentId === 'client14mob') {
            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'block';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.add('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === 'client15mob') {
            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'block';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.add('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === 'client16mob') {
            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'block';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.add('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === 'client17mob') {
            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'block';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob14").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.add('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        } else if (currentId === '') {
            document.getElementById("clientname1mob").style.display = "none";
            document.getElementById("clientname2mob").style.display = 'none';
            document.getElementById("clientname3mob").style.display = 'none';
            document.getElementById("clientname4mob").style.display = 'none';
            document.getElementById("clientname5mob").style.display = 'none';
            document.getElementById("clientname6mob").style.display = 'none';
            document.getElementById("clientname7mob").style.display = 'none';
            document.getElementById("clientname8mob").style.display = 'none';
            document.getElementById("clientname9mob").style.display = 'none';
            document.getElementById("clientname10mob").style.display = 'none';
            document.getElementById("clientname11mob").style.display = 'none';
            document.getElementById("clientname12mob").style.display = 'none';
            document.getElementById("clientname13mob").style.display = 'none';
            document.getElementById("clientname14mob").style.display = 'none';
            document.getElementById("clientname15mob").style.display = 'none';
            document.getElementById("clientname16mob").style.display = 'none';
            document.getElementById("clientname17mob").style.display = 'none';

            document.getElementById("dotclientsmob1").classList.remove('dotactive');
            document.getElementById("dotclientsmob2").classList.remove('dotactive');
            document.getElementById("dotclientsmob3").classList.remove('dotactive');
            document.getElementById("dotclientsmob4").classList.remove('dotactive');
            document.getElementById("dotclientsmob5").classList.remove('dotactive');
            document.getElementById("dotclientsmob6").classList.remove('dotactive');
            document.getElementById("dotclientsmob7").classList.remove('dotactive');
            document.getElementById("dotclientsmob8").classList.remove('dotactive');
            document.getElementById("dotclientsmob9").classList.remove('dotactive');
            document.getElementById("dotclientsmob10").classList.remove('dotactive');
            document.getElementById("dotclientsmob11").classList.remove('dotactive');
            document.getElementById("dotclientsmob12").classList.remove('dotactive');
            document.getElementById("dotclientsmob13").classList.remove('dotactive');
            document.getElementById("dotclientsmob15").classList.remove('dotactive');
            document.getElementById("dotclientsmob16").classList.remove('dotactive');
            document.getElementById("dotclientsmob17").classList.remove('dotactive');
        }
    }

}

window.changeContent14mob = function changeContent14mob(currentId) {

    var Clientname1 = document.getElementById("clientname1mob");
    var Clientname2 = document.getElementById("clientname2mob");
    var Clientname3 = document.getElementById("clientname3mob");
    var Clientname4 = document.getElementById("clientname4mob");
    var Clientname5 = document.getElementById("clientname5mob");
    var Clientname6 = document.getElementById("clientname6mob");
    var Clientname7 = document.getElementById("clientname7mob");
    var Clientname8 = document.getElementById("clientname8mob");
    var Clientname9 = document.getElementById("clientname9mob");
    var Clientname10 = document.getElementById("clientname10mob");
    var Clientname11 = document.getElementById("clientname11mob");
    var Clientname12 = document.getElementById("clientname12mob");
    var Clientname13 = document.getElementById("clientname13mob");
    var Clientname14 = document.getElementById("clientname14mob");
    var Clientname15 = document.getElementById("clientname15mob");
    var Clientname16 = document.getElementById("clientname16mob");
    var Clientname17 = document.getElementById("clientname17mob");


    var dot4 = document.getElementById("dotclientsmob1");
    var dot5 = document.getElementById("dotclientsmob2");
    var dot6 = document.getElementById("dotclientsmob3");
    var dot7 = document.getElementById("dotclientsmob4");
    var dot8 = document.getElementById("dotclientsmob5");
    var dot9 = document.getElementById("dotclientsmob6");
    var dot10 = document.getElementById("dotclientsmob7");
    var dot11 = document.getElementById("dotclientsmob8");
    var dot12 = document.getElementById("dotclientsmob9");
    var dot13 = document.getElementById("dotclientsmob10");
    var dot14 = document.getElementById("dotclientsmob11");
    var dot15 = document.getElementById("dotclientsmob12");
    var dot16 = document.getElementById("dotclientsmob13");
    var dot17 = document.getElementById("dotclientsmob14");
    var dot1 = document.getElementById("dotclientsmob15");
    var dot2 = document.getElementById("dotclientsmob16");
    var dot3 = document.getElementById("dotclientsmob17");

    var isPaused = false;

    var list = document.getElementById("product_listclientsmob");
    list.addEventListener("click", function (e) {
        if (e.target && e.target.matches("li.item")) {
            e.target.className = "foo";

            alert("clicked " + e.target.innerText);
        }
    });

    window.isPaused = true;
    var firstLI = document.getElementById("product_listclientsmob").firstElementChild;
    var Client1Li = document.getElementById("client1mob");
    var Client2Li = document.getElementById("client2mob");
    var Client3Li = document.getElementById("client3mob");
    var Client4Li = document.getElementById("client4mob");
    var Client5Li = document.getElementById("client5mob");
    var Client6Li = document.getElementById("client6mob");
    var Client7Li = document.getElementById("client7mob");
    var Client8Li = document.getElementById("client8mob");
    var Client9Li = document.getElementById("client9mob");
    var Client10Li = document.getElementById("client10mob");
    var Client11Li = document.getElementById("client11mob");
    var Client12Li = document.getElementById("client12mob");
    var Client13Li = document.getElementById("client13mob");
    var Client14Li = document.getElementById("client14mob");
    var Client15Li = document.getElementById("client15mob");
    var Client16Li = document.getElementById("client16mob");
    var Client17Li = document.getElementById("client17mob");


    setTimeout(() => {
        window.isPaused = false;

    }, 5000);

    if (currentId === 'client1mob' || currentId === "dotclientsmob15") {


        firstLI.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);


        document.getElementById("clientname1mob").style.display = 'block';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';


        dot1.classList.add('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');

    } else if (currentId === 'client2mob' || currentId === "dotclientsmob16") {

        firstLI.insertAdjacentElement("afterend", Client12Li);

        Client12Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'block';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.add('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client3mob' || currentId === "dotclientsmob17") {

        firstLI.insertAdjacentElement("afterend", Client13Li);


        Client13Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'block';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.add('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client4mob' || currentId === "dotclientsmob1") {

        firstLI.insertAdjacentElement("afterend", Client1Li);


        Client1Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname14.style.display = 'none';
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'block';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.add('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client5mob' || currentId === "dotclientsmob2") {
        firstLI.insertAdjacentElement("afterend", Client2Li);



        Client2Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'block';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.add('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client6mob' || currentId === "dotclientsmob3") {

        firstLI.insertAdjacentElement("afterend", Client3Li);


        Client3Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'block';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.add('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client7mob' || currentId === "dotclientsmob4") {

        firstLI.insertAdjacentElement("afterend", Client4Li);



        Client4Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'block';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.add('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client8mob' || currentId === "dotclientsmob5") {

        firstLI.insertAdjacentElement("afterend", Client5Li);


        Client5Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'block';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.add('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client9mob' || currentId === "dotclientsmob6") {

        firstLI.insertAdjacentElement("afterend", Client6Li);



        Client6Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'block';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.add('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client10mob' || currentId === "dotclientsmob7") {

        firstLI.insertAdjacentElement("afterend", Client7Li);


        Client7Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'block';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.add('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client11mob' || currentId === "dotclientsmob8") {

        firstLI.insertAdjacentElement("afterend", Client8Li);



        Client8Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'block';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.add('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client12mob' || currentId === "dotclientsmob9") {


        firstLI.insertAdjacentElement("afterend", Client9Li);


        Client9Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'block';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.add('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client13mob' || currentId === "dotclientsmob10") {


        firstLI.insertAdjacentElement("afterend", Client10Li);



        Client10Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'block';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.add('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client14mob' || currentId === "dotclientsmob11") {


        firstLI.insertAdjacentElement("afterend", Client10Li);



        Client10Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'block';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.add('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client15mob' || currentId === "dotclientsmob12") {


        firstLI.insertAdjacentElement("afterend", Client14Li);



        Client14Li.insertAdjacentElement("afterend", Client15Li);
        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Client17Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'block';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.add('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client16mob' || currentId === "dotclientsmob13") {


        firstLI.insertAdjacentElement("afterend", Client15Li);



        Client15Li.insertAdjacentElement("afterend", Client16Li);
        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Client17Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client14Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'block';
        Clientname17.style.display = 'none';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.add('dotactive');
        dot17.classList.remove('dotactive');
    } else if (currentId === 'client17mob' || currentId === "dotclientsmob14") {


        firstLI.insertAdjacentElement("afterend", Client16Li);



        Client16Li.insertAdjacentElement("afterend", Client17Li);
        Client17Li.insertAdjacentElement("afterend", Client1Li);
        Client1Li.insertAdjacentElement("afterend", Client2Li);
        Client2Li.insertAdjacentElement("afterend", Client3Li);
        Client3Li.insertAdjacentElement("afterend", Client4Li);
        Client4Li.insertAdjacentElement("afterend", Client5Li);
        Client5Li.insertAdjacentElement("afterend", Client6Li);
        Client6Li.insertAdjacentElement("afterend", Client7Li);
        Client7Li.insertAdjacentElement("afterend", Client8Li);
        Client8Li.insertAdjacentElement("afterend", Client9Li);
        Client9Li.insertAdjacentElement("afterend", Client10Li);
        Client10Li.insertAdjacentElement("afterend", Client11Li);
        Client11Li.insertAdjacentElement("afterend", Client12Li);
        Client12Li.insertAdjacentElement("afterend", Client13Li);
        Client13Li.insertAdjacentElement("afterend", Client14Li);
        Client14Li.insertAdjacentElement("afterend", Client15Li);

        Clientname1.style.display = 'none';
        Clientname2.style.display = 'none';
        Clientname3.style.display = 'none';
        Clientname4.style.display = 'none';
        Clientname5.style.display = 'none';
        Clientname6.style.display = 'none';
        Clientname7.style.display = 'none';
        Clientname8.style.display = 'none';
        Clientname9.style.display = 'none';
        Clientname10.style.display = 'none';
        Clientname11.style.display = 'none';
        Clientname12.style.display = 'none';
        Clientname13.style.display = 'none';
        Clientname14.style.display = 'none';
        Clientname15.style.display = 'none';
        Clientname16.style.display = 'none';
        Clientname17.style.display = 'block';

        dot1.classList.remove('dotactive');
        dot2.classList.remove('dotactive');
        dot3.classList.remove('dotactive');
        dot4.classList.remove('dotactive');
        dot5.classList.remove('dotactive');
        dot6.classList.remove('dotactive');
        dot7.classList.remove('dotactive');
        dot8.classList.remove('dotactive');
        dot9.classList.remove('dotactive');
        dot10.classList.remove('dotactive');
        dot11.classList.remove('dotactive');
        dot12.classList.remove('dotactive');
        dot13.classList.remove('dotactive');
        dot14.classList.remove('dotactive');
        dot15.classList.remove('dotactive');
        dot16.classList.remove('dotactive');
        dot17.classList.add('dotactive');
    }




}

window.clientTimermob = function clientTimermob() {
    setInterval(onTimerChangemob, 5000);
}

window.addEventListener('load', () => {
    clientTimermob();

});